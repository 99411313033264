<template>
    <!-- 面包屑导航组件 -->
    <!-- <div class="breadcrumb_box"> -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item 
                v-for="item in breadcrumb"
                :key="item.id"
                :to="{ path: item.path }">{{item.path_name}}</el-breadcrumb-item>
        </el-breadcrumb>
    <!-- </div> -->
</template>

<script>
export default {
    props:["breadcrumb"],
}
</script>

<style scoped>

</style>
